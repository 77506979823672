import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../pages/components/layout";
import AudioPlayer from "../pages/components/audioplayer";
import "../css/index.css";
import Saw from "../images/sketches/saw.svg";
import File from "../images/sketches/file.svg";
import Candle from "../images/sketches/candle.svg";
import Next from "../images/logos/next-button.svg";
import Previous from "../images/logos/previous-button.svg";
import Slide from "react-reveal/Slide";
import TestAudio from "../audio/WATERFORD Stop9.1.mp3";

function List(props) {
  return (
    <Slide left>
      <li className="list-arrow py-4 pl-2 text-white">
        <a href={props.link}>{props.name}</a>
      </li>
    </Slide>
  );
}

export default function ArundelSquare() {
  const [isActive, setIsActive] = useState(false);

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  const cards = [
    {
      id: 13,
      cardName: "Bishops Palace",
      text: "https://www.waterfordtreasures.com/bishops-palace",
      active: true,
    },
  ];

  return (
    <Layout pageTitle="Arundel Square">
    <div className="lg:hidden">

      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - Arundel Square</title>
      <meta name="description" content="Arundel Square. A location on digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - Arundel Square" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/arundelsquare" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>

      <div className="w-full bg-waterford-ocean h-24"></div>
      <section className="arundel-bg w-full">
        <div className="w-9/12 text-white">
          <h1 className="font-lower p-6">
            <span className="font-heading text-xl"><Trans>ArundelSquare</Trans></span>
            <br />
            <span className="font-bold text-4xl"><Trans>ArundelSquareSubtitle</Trans></span>
          </h1>
        </div>
        <div className="pt-16 text-center">
          <div className=" text-center">
            <a
              href="https://arweb.app/?zid=z/XHvo1c&rs=&menu=&toolbar="
            >
              <div className="ar-orange-bubble rounded-full h-32 w-32 bg-waterford-orange hover:bg-opacity-100 mx-auto ml-8 md:ml-auto">
                <div className="animate-pulse transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-16 rounded-full mx-auto ring ring-waterford-orange"></div>
              </div>
            </a>
            <div>
              <AudioPlayer url={TestAudio} transcript={'/transcripts/flemingtranscript'}/>
            </div>
          </div>
        </div>
      </section>

      <div>
        <div className="accordion pt-16 bg-waterford-ocean"></div>
      </div>

      <button className="p-6 w-full bg-waterford-ocean ring ring-waterford-cyan-30 text-waterford-orange text-left">
        <div
          className="accordion-title grid grid-cols-6 py-6"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="col-span-5 font-lower text-2xl">
            <Trans>LearnMore</Trans>
          </div>
        </div>
        {isActive && (
          <>
            <ul className="accordion-content list-outside font-heading text-xl pl-12">
              {cards.map((card) => (
                <List key={card.id} name={card.cardName} link={card.text} />
              ))}
            </ul>
          </>
        )}
      </button>

      <section className="location-hero py-16">
        <div class="tab-content" id="tabs-tabContentJustify">
          <div
            class="tab-pane fade show active"
            id="tabs-homeJustify"
            role="tabpanel"
            aria-labelledby="tabs-home-tabJustify"
          >
            <div className="w-7/12">
              <h1 className="font-lower text-white p-6">
                <br />
                <span className="font-bold text-4xl"><Trans>DidYouKnow</Trans></span>
              </h1>
            </div>
            <div className="text-waterford-red w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-6 lg:mx-auto">1</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>ArundelFactOne</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>ArundelFactContentOne</Trans>
                </p>
              </div>
              <div className="py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <img className="w-full col-span-2" src={Saw} alt="Saw" />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <div></div>
                <p className="text-xl col-span-2 font-bold mt-2">
                <Trans>ArundelFactTwo</Trans>
                </p>
                <h3 className="text-8xl lg:mx-auto">2</h3>
              </div>
              <div className="w-6/6 py-6 grid grid-cols-4 lg:mx-auto font-heading">
                <div></div>
                <p className="text-white text-sm col-span-2">
                <Trans>ArundelFactContentTwo</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide left>
                  <img
                    className="w-full col-span-3 px-4"
                    src={File}
                    alt="File"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-blue w-6/6 py-6">
              <div className="w-7/12 pt-16">
                <h1 className="font-lower text-white p-6">
                  <span className="font-heading text-xl"><Trans>ArundelSquare</Trans></span>
                  <br />
                  <span className="font-bold text-4xl"><Trans>Timeline</Trans></span>
                </h1>
              </div>
              <div className="text-waterford-red w-6/6 py-6 px-6">
                <div className="grid grid-cols-2 gap-0 font-lower text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1777</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                  <Trans>ArundelTimelineOne</Trans>

                  </p>
                </div>

                <div className="py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <img className="w-3/6 col-span-2" src={Candle} alt="Candle" />
                </Slide>
              </div>

                <div className="grid grid-cols-2 gap-0 font-lower text-left">
                  <h3 className="text-6xl lg:mx-auto pl-3">1778</h3>
                  <div></div>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 text-left">
                  <p className="text-white text-sm col-span-2">
                  <Trans>ArundelTimelineTwo</Trans>
                  </p>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div>
            <Link to="/applemarket">
              <img className="w-2/6 mx-auto" src={Previous} alt="Previous" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>PreviousStop</Trans>
            </p>
          </div>
          <div>
            <Link to="/blackfriars">
              <img className="w-2/6 mx-auto" src={Next} alt="Next" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>NextStop</Trans>
            </p>
          </div>
        </div>
      </section>
    </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;